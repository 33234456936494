<ng-container *ngIf='arenasData$ | async as arenasData'>
  <div class='arenas-search'>
    <nz-input-group nzSearch>
      <input #srchInput type="text" (input)='onSearchChange(srchInput.value)' nz-input placeholder="Search..." />
    </nz-input-group>
  </div>
  <nz-table
    #tableComponent
    nzBordered
    [nzData]="arenasData.data"
    [nzTableLayout]="tableLayout"
    [nzShowPagination]="tableListConfig.isShowDefaultTablePagination">
    <thead>
    <tr>
      <th>Name</th>
      <th>Phone</th>
      <th>Email</th>
      <th>Created</th>
      <th>Country</th>
      <th>Status</th>
      <th *ngxPermissionsOnly="['arenas_action']">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr class='arena-row' *ngFor="let arena of arenasData.data" (click)='goToDetails(arena)'>
      <td>{{ arena.location.name }}</td>
      <td>{{ arena.phoneNumber }}</td>
      <td>{{ arena.email }}</td>
      <td>{{ arena.createDate | date: 'longDate' }}</td>
      <td>{{ arena.country }}</td>
      <td id="arena-status">
        <nz-tag [nzColor]="getNzBadgeStatus(arena.location.isPending)">
          <i nz-icon nzType="check-circle" [nzSpin]="arena.location.isPending"></i>
          <span>{{ getArenaStatus(arena.location.isPending) }}</span>
        </nz-tag>
      </td>
      <td *ngxPermissionsOnly="['arenas_action']">
        <button nz-button nz-dropdown [nzDropdownMenu]="actionMenu">
          Action
          <i nz-icon nzType="down"></i>
        </button>
        <nz-dropdown-menu #actionMenu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item [nzDisabled]="!arena.images.length" (click)="viewArena(arena)">View</li>
            <li nz-menu-item [nzDisabled]="!arena.location.isPending"
                nz-popconfirm="" nzPopconfirmTitle="Sure to approve?"
                (nzOnConfirm)="approveArena(arena)"
            >Approve
            </li>
            <li nz-menu-item nzDisabled><del>Edit</del></li>
            <li nz-menu-divider></li>
            <li nz-menu-item nzDisabled><del>Remove</del></li>
          </ul>
        </nz-dropdown-menu>
      </td>
    </tr>
    </tbody>
  </nz-table>

  <br>
  <nz-pagination [nzPageIndex]="activePage" [nzTotal]="arenasData.count" (nzPageIndexChange)="getNextPage($event)"></nz-pagination>

  <app-arena-modal-carousel></app-arena-modal-carousel>

</ng-container>
