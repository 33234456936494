<ng-container *ngIf='fansData$ | async as data'>
  <div class='fans-search'>
    <nz-input-group nzSearch>
      <input #srchInput type="text" (input)='onSearchChange(srchInput.value)' nz-input placeholder="Search..." />
    </nz-input-group>
  </div>

  <nz-table
    #tableComponent
    nzBordered
    [nzData]="data.data"
    [nzTableLayout]="tableLayout"
    [nzShowPagination]="tableListConfig.isShowDefaultTablePagination"
    [nzLoading]="preloadService.isLoad$ | async"
    style="overflow: auto;"
  >
    <thead>
    <tr class="fan-list-table">
      <th>Id</th>
      <th>First name</th>
      <th>Last name</th>
      <th>Full name</th>
      <th>Nickname</th>
      <th>Email</th>
      <th>Country</th>
      <th>Last visit</th>
      <th>Created</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of data.data" class="fan-list-table fan-row" (click)="goToDetails(data)">
      <td>{{ data.id }}</td>
      <td>{{ data.firstName }}</td>
      <td>{{ data.lastName }}</td>
      <td>{{ data.fullname }}</td>
      <td>{{ data.nickname }}</td>
      <td>
        <span nz-tooltip [nzTooltipTitle]="data.email">
          {{ formatStr(data.email) }}
        </span>
      </td>
      <td>{{ data.country }}</td>
      <td>{{ data.lastVisit | date: 'longDate' }}</td>
      <td>{{ data.created | date: 'longDate' }}</td>
    </tr>
    </tbody>
  </nz-table>

  <br>

  <nz-pagination [nzPageIndex]="activePage" [nzTotal]="data.count" (nzPageIndexChange)="getNextPage($event)"></nz-pagination>
</ng-container>
