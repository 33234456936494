<nz-layout *ngIf="fighter">
  <div class="site-page-header-ghost-wrapper">
    <nz-page-header nzBackIcon [nzGhost]="false">
      <nz-page-header-title>
        <svg *ngIf="fighter.verified" class="verified-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            d="M12.3 2.9c.1.1.2.1.3.2.7.6 1.3 1.1 2 1.7.3.2.6.4.9.4.9.1 1.7.2 2.6.2.5 0 .6.1.7.7.1.9.1 1.8.2 2.6 0 .4.2.7.4 1 .6.7 1.1 1.3 1.7 2 .3.4.3.5 0 .8-.5.6-1.1 1.3-1.6 1.9-.3.3-.5.7-.5 1.2-.1.8-.2 1.7-.2 2.5 0 .4-.2.5-.6.6-.8 0-1.6.1-2.5.2-.5 0-1 .2-1.4.5-.6.5-1.3 1.1-1.9 1.6-.3.3-.5.3-.8 0-.7-.6-1.4-1.2-2-1.8-.3-.2-.6-.4-.9-.4-.9-.1-1.8-.2-2.7-.2-.4 0-.5-.2-.6-.5 0-.9-.1-1.7-.2-2.6 0-.4-.2-.8-.4-1.1-.6-.6-1.1-1.3-1.6-2-.4-.4-.3-.5 0-1 .6-.6 1.1-1.3 1.7-1.9.3-.3.4-.6.4-1 0-.8.1-1.6.2-2.5 0-.5.1-.6.6-.6.9-.1 1.7-.1 2.6-.2.4 0 .7-.2 1-.4.7-.6 1.4-1.2 2.1-1.7.1-.2.3-.3.5-.2z"
            style="fill: rgb(51,144,236)"></path>
          <path class="lol"
                d="M16.4 10.1l-.2.2-5.4 5.4c-.1.1-.2.2-.4 0l-2.6-2.6c-.2-.2-.1-.3 0-.4.2-.2.5-.6.7-.6.3 0 .5.4.7.6l1.1 1.1c.2.2.3.2.5 0l4.3-4.3c.2-.2.4-.3.6 0 .1.2.3.3.4.5.2 0 .3.1.3.1z"
                style="fill: white"></path>
        </svg>
        {{fighter.nickname}}
      </nz-page-header-title>
      <nz-page-header-subtitle>{{fighter.firstName}} {{fighter.lastName}}</nz-page-header-subtitle>
      <nz-page-header-tags>
        <nz-tag [nzColor]="'blue'">Active: {{fighter.isActiveToString}}</nz-tag>
      </nz-page-header-tags>
      <!--      <nz-page-header-extra>-->
      <!--        <nz-space>-->
      <!--          <button *nzSpaceItem nz-button>Operation</button>-->
      <!--          <button *nzSpaceItem nz-button>Operation</button>-->
      <!--          <button *nzSpaceItem nz-button nzType="primary">Primary</button>-->
      <!--        </nz-space>-->
      <!--      </nz-page-header-extra>-->
    </nz-page-header>
  </div>
  <nz-content class="inner-content">
    <nz-spin [nzSpinning]="false">
      <nz-row [nzGutter]="[16, 8]">
        <nz-col [nzSpan]="12" [nzMd]="24" [nzXl]="6">
          <nz-card>
            <nz-row [nzGutter]="[16, 16]">
              <nz-card style="width:300px; position: relative;" [nzCover]="coverTemplate">
                <nz-card-meta [nzTitle]="fighter.fullName" [nzDescription]="fighter.email"></nz-card-meta>
                <nz-timeline class="timeline">
                  <nz-timeline-item *ngFor="let item of timelineItems" [nzColor]="item.color">
                    {{item.title}}: {{item.value}}
                  </nz-timeline-item>
                </nz-timeline>
                <ng-container *ngIf="!fighter.verified">
                  <button *ngxPermissionsOnly="[permissions.VerifyUser]" nz-button nzType="primary" (click)="onVerifyClick()">Verify</button>
                </ng-container>
              </nz-card>
              <ng-template #coverTemplate>
                <div style="text-align: center">
                  <img *ngIf="fighter.penaltyInfo?.banned" class="banned-image" src="assets/images/banned.png"
                       alt="banned">
                  <img class="fighter-image" style="margin-top: 10px;"
                       [ngStyle]="{ 'opacity': fighter.penaltyInfo?.banned ? 0.3 : 1 }"
                       [src]="fighter.fullFaceAvatar?.href || previewImage" />
                </div>
              </ng-template>
            </nz-row>
          </nz-card>
        </nz-col>
        <nz-col [nzSpan]="12" [nzMd]="24" [nzXl]="16">
          <nz-row nzAlign="top" [nzGutter]="[16, 16]">
            <nz-tabset style="width: 100%; padding: 10px">
              <nz-tab nzTitle="Penalties">
                <nz-col nzFlex="1">
                  <nz-modal [(nzVisible)]="addPenaltyModalVisible" nzOkText="Save" (nzOnCancel)="handleCancel()"
                            (nzOnOk)="onSavePenaltyClick()">
                    <ng-container *nzModalContent>
                      <nz-row class="row">
                        <div><b>Penalty</b></div>
                        <div>
                          <a nz-dropdown [nzDropdownMenu]="menuPenalties">
                            {{ getPenaltyPreview(selectedPenalty) }}
                            <i nz-icon nzType="down"></i>
                          </a>
                          <nz-dropdown-menu #menuPenalties="nzDropdownMenu">
                            <ul nz-menu nzSelectable>
                              <li *ngFor="let penalty of penalties" nz-menu-item
                                  (click)="onPenaltySelect(penalty)">{{ getPenaltyPreview(penalty) }}</li>
                            </ul>
                          </nz-dropdown-menu>
                        </div>
                      </nz-row>
                      <nz-row class="row">
                        <div><b>Comment</b></div>
                        <div>
                          <a nz-dropdown [nzDropdownMenu]="menuComments">
                            {{ selectedComment }}
                            <i nz-icon nzType="down"></i>
                          </a>
                          <nz-dropdown-menu #menuComments="nzDropdownMenu">
                            <ul nz-menu nzSelectable>
                              <li *ngFor="let comment of comments" (click)="onCommentSelect(comment)"
                                  nz-menu-item>{{ comment }}</li>
                            </ul>
                          </nz-dropdown-menu>
                        </div>
                      </nz-row>
                      <nz-row *ngIf="selectedComment === 'Other'">
                        <input nz-input placeholder="Input comment..." [(ngModel)]="inputCommentValue" />
                      </nz-row>
                    </ng-container>
                  </nz-modal>
                  <ul nz-list nzBordered nzSize="large">
                    <nz-list-header>
                      <div class="penalties-header">
                        Penalties
                        <button *ngxPermissionsOnly="[permissions.AddFighterPenalties]" class="add-penalty"
                                (click)="onAddPenaltyClick()" nzType="primary" nz-button nzShape="circle"><i
                          class="add-penalty-icon" nz-icon nzType="plus-circle"></i></button>
                      </div>
                    </nz-list-header>
                    <li nz-list-item *ngFor="let penalty of fighter.penalties" nzNoFlex>
                      <p nz-typography><b>[{{ getPenaltyPreview(penalty.reason) }}]</b> - {{ penalty.comment }}</p>
                      <div *ngxPermissionsOnly="[permissions.RemoveFighterPenalties]" style="text-align: right"><a
                        style="color: rgba(255, 77, 79, .9)" (click)="onDeletePenalty(penalty.id)">Remove</a></div>
                    </li>
                  </ul>
                </nz-col>
              </nz-tab>
              <nz-tab *ngxPermissionsOnly="[permissions.ViewFighterAccountInfo]" nzTitle="Account info">
                <user-details-account-info *ngIf="fighter?.account" [user]="fighter"></user-details-account-info>
              </nz-tab>
              <nz-tab *ngxPermissionsOnly="[permissions.MatchFighters]" nzTitle="Matchmaking">
                <fighter-details-matching *ngIf="fighter" [fighter]="fighter"></fighter-details-matching>
              </nz-tab>
            </nz-tabset>
          </nz-row>
          <nz-row [nzGutter]="[16, 16]">
            <nz-col nzFlex="auto">

            </nz-col>
          </nz-row>
        </nz-col>
      </nz-row>
    </nz-spin>
  </nz-content>
</nz-layout>
