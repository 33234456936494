<nz-layout class="layout">
  <nz-sider #sider [nzTrigger]="null" nzCollapsible="true" nzReverseArrow="true">
    <div class="logo"></div>
    <div class="user-info">
      <span class="email" nz-typography>{{ userEmail$ | async }}</span>
    </div>
    <ul nz-menu nzMode="inline" nzTheme="dark">
      <li nz-submenu nzIcon="home" nzTitle="Home">
        <ul>
          <li *ngxPermissionsOnly="[permissions.ViewArenas]" nz-menu-item>
            <a [routerLink]="'arenas'">Arenas</a>
          </li>
          <li *ngxPermissionsOnly="[permissions.ViewFighters]" nz-menu-item>
            <a [routerLink]="'fighters'">Fighters</a>
          </li>
          <li *ngxPermissionsOnly="[permissions.ViewFans]" nz-menu-item>
            <a [routerLink]="'fans'">Fans</a>
          </li>
        </ul>
      </li>
      <li nz-submenu nzIcon="user" nzTitle="Users">
        <ul>
          <li *ngxPermissionsOnly="[permissions.CreateNewUsers]" nz-menu-item>
            <a [routerLink]="'users-management/create-user'">Create user</a>
          </li>
          <!--            <li nz-menu-item *ngxPermissionsOnly="['view_users']">-->
          <!--              <a [routerLink]="'users-management/manage'">Manage</a>-->
          <!--            </li>-->
        </ul>
      </li>
      <li *ngxPermissionsOnly="[permissions.DownloadUsersStatistics]" nz-submenu nzIcon="team" nzTitle="Statistics">
        <ul>
          <li nz-menu-item>
            <a [routerLink]="'statistics/users'">Users</a>
          </li>
          <li nz-menu-item>
            <a [routerLink]="'statistics/videos'">Videos</a>
          </li>
          <li nz-menu-item>
            <a [routerLink]="'statistics/feedbacks'">Feedbacks</a>
          </li>
        </ul>
      </li>
      <li *ngxPermissionsOnly="[permissions.DownloadUsersStatistics]" nz-submenu nzIcon="build" nzTitle="Matchmaking">
        <ul>
          <li nz-menu-item>
            <a [routerLink]="'matchmaking/manage'">Manage</a>
          </li>
        </ul>
      </li>
      <li nz-submenu nzIcon="team" nzTitle="Manage">
        <ul>
          <li *ngxPermissionsOnly="[permissions.SuperAdmin]" nz-menu-item>
            <a [routerLink]="'manage/admins'">Admins</a>
          </li>
          <li *ngxPermissionsOnly="[permissions.ManageBanners]" nz-menu-item>
            <a [routerLink]="'manage/banners'">Banners</a>
          </li>
        </ul>
      </li>
    </ul>
    <button (click)="onLogout()" [nzSize]="'default'" class="logout-btn" nz-button nz-tooltip nzShape="circle"
            nzTooltipTitle="Logout">
      <i class="logout-icon" nz-icon nzTheme="outline" nzType="logout"></i>
    </button>
  </nz-sider>
  <nz-layout class="right-layout">
    <router-outlet></router-outlet>
  </nz-layout>
</nz-layout>
